<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Configuraciones</h4>
            <div class="small text-muted">Configuración general del sitio web</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body>
        <b-tabs pills card vertical>
          <b-tab title="PLANTILLA" active>
            
              <b-card header-text-variant="white"
                      header-bg-variant="dark"
                      header-border-variant="dark"
                      footer-text-variant="dark"
                      footer-bg-variant="ligth"
                      footer-border-variant="secondary"> 

                <template v-slot:header>
                  <strong> PLANTILLA </strong>  
                </template>
                
                <div v-if="crud.template.statusSave" class="text-center"> 
                  <b-spinner></b-spinner>
                </div>
                <b-row v-else>
                  <b-col md="12">
                    <b-tabs pills card>
                      <b-tab title="Configuración" active>
                        <b-row>
                          <b-col md="12">
                            <b-form-group label="Plantilla">
                              <b-form-select v-model="crud.template.values.template" :options="arr.select.template" @input="selectHeader()"></b-form-select>
                            </b-form-group>
                          </b-col> 
                          <b-col md="12">
                            <b-form-group label="Cabecera">
                              <b-form-select v-model="crud.template.values.header" :options="arr.select.header"></b-form-select>
                            </b-form-group>
                          </b-col>   
                        </b-row>
                      </b-tab>
                      <b-tab title="Top Bar">
                        <b-row>
                          <b-col md="12">
                            <b-form-group label="Leyenda Topbar">
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.template.values.legend_topbar"                                    
                                            placeholder="Ingresar una leyenda">
                              </b-form-input>
                            </b-form-group>
                          </b-col>                                                           
                        </b-row>                        
                      </b-tab> 

                      <b-tab title="Accesos Laterales">
                        <b-row>
                          <b-col md="12" class="mt-3">
                            <b-form-checkbox                  
                              v-model="crud.template.values.not_showing_access_right"
                              switch
                            >
                              ¿No mostrar accesos en el lateral derecho del sitio web?
                            </b-form-checkbox>
                          </b-col>   
                        </b-row>
                      </b-tab>

                      <b-tab title="Colores">
                        <b-row>
                          <b-col md="12">
                            <b-form-group label="Color Primario">
                              <b-form-input v-model="crud.template.values.color_primary" type="color"></b-form-input>
                            </b-form-group>
                          </b-col> 
                          <b-col md="12">
                            <b-form-group label="Color Secundario">
                              <b-form-input v-model="crud.template.values.color_secondary" type="color"></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-tab>
                      <b-tab title="Fuentes">
                        <b-row>
                          <b-col md="12">
                            <b-form-group label="Fonts Primaria">
                              <b-form-input type="url"
                                            size="sm"
                                            v-model="crud.template.values.fonts_primary"                                    
                                            placeholder="Ingresar url fonts primaria">
                              </b-form-input>
                            </b-form-group>
                          </b-col>                                          
                          <b-col md="12">
                            <b-form-group label="Fonts Secundaria">
                              <b-form-input type="url"
                                            size="sm"
                                            v-model="crud.template.values.fonts_secondary"                                    
                                            placeholder="Ingresar url fonts secundaria">
                              </b-form-input>
                            </b-form-group>
                          </b-col>   
                          <b-col md="12">
                            <b-form-group label="Color Principal de la Fuente">
                              <b-form-input v-model="crud.template.values.fonts_color" type="color"></b-form-input>
                            </b-form-group>
                          </b-col>                                                                                                 
                        </b-row>
                      </b-tab>
                    </b-tabs>
                  </b-col>
                </b-row>            
                <template v-slot:footer>
                  <b-button variant="dark" @click="save('template')" size="sm" class="pull-right">Guardar</b-button>          
                </template> 
              </b-card>                  
            
          </b-tab>
          <b-tab title="GENERAL">          
            <b-card header-text-variant="white"
                    header-bg-variant="dark"
                    header-border-variant="dark"
                    footer-text-variant="dark"
                    footer-bg-variant="ligth"
                    footer-border-variant="secondary"> 

              <template v-slot:header>
                <strong> GENERAL </strong>  
              </template>

              <div v-if="crud.general.statusSave" class="text-center"> 
                <b-spinner></b-spinner>
              </div>
              <b-row v-else>      
                <b-col md="12">
                  <b-tabs pills card>
                    <b-tab title="Nombre" active>
                      <b-row>
                        <b-col md="12">           
                          <b-form-group label="URL Sitio Web" description="URL del sitio web sin la '/' al final">
                            <b-form-input type="url"
                                          size="sm"
                                          v-model="crud.general.values.url_siteweb"                                    
                                          placeholder="Ingresar la url del sitio web">
                            </b-form-input>
                          </b-form-group>
                        </b-col>    

                        <b-col md="12">
                          <b-form-group label="Título">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.general.values.title"                                    
                                          placeholder="Ingresar un título">
                            </b-form-input>
                          </b-form-group>
                        </b-col>                                                 
                        <b-col md="12">
                          <hr>
                        </b-col>
                        <b-col md="12" class="mt-2">
                          <b-form-checkbox                  
                            v-model="crud.general.values.activar_titoloconstruye"
                            switch
                          >
                            Activar Tito Lo Construye
                          </b-form-checkbox>
                        </b-col>                        
                      </b-row>
                    </b-tab>
                    <b-tab title="Ubicación">
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="Dirección">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.general.values.address"                                    
                                          placeholder="Ingresar una dirección">
                            </b-form-input>
                          </b-form-group>
                        </b-col>                       
                      </b-row>
                    </b-tab>                    
                    <b-tab title="Contacto">
                      <b-row>
                        <b-col md="6">
                          <b-row>
                            <b-col md="12">
                              <b-form-group label="Teléfono">
                                <b-form-input type="text"
                                              size="sm"
                                              v-model="crud.general.values.phone"                                    
                                              placeholder="Ingresar un teléfono">
                                </b-form-input>
                              </b-form-group>                        
                            </b-col> 
                            <b-col md="12">
                              <b-form-checkbox                  
                                v-model="crud.general.values.phone_permissions"
                                switch
                              >
                                ¿Mostrar teléfono solo a usuarios logueados?
                              </b-form-checkbox>
                            </b-col>    
                          </b-row>                      
                        </b-col>
                        <b-col md="6">
                          <b-row>
                            <b-col md="12">
                              <b-form-group label="Wathsapp">
                                <b-form-input type="text"
                                              size="sm"
                                              v-model="crud.general.values.whatsapp"                                    
                                              placeholder="Ingresar un teléfono de Whatsapp">
                                </b-form-input>
                              </b-form-group>
                            </b-col> 
                            <b-col md="12">
                              <b-form-checkbox                  
                                v-model="crud.general.values.whatsapp_permissions"
                                switch
                              >
                                ¿Mostrar whatsapp solo a usuarios logueados?
                              </b-form-checkbox>
                            </b-col>
                          </b-row>                           
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="6">
                          <b-row>
                            <b-col md="12" class="mt-3">
                              <b-form-group label="Email">
                                <b-form-input type="email"
                                              size="sm"
                                              v-model="crud.general.values.email"                                    
                                              placeholder="Ingresar un email">
                                </b-form-input>
                              </b-form-group>
                            </b-col> 
                            <b-col md="12">
                              <b-form-checkbox                  
                                v-model="crud.general.values.email_permissions"
                                switch
                              >
                                ¿Mostrar email solo a usuarios logueados?
                              </b-form-checkbox>
                            </b-col>                           
                          </b-row>                          
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab title="Redes">
                      <b-row>
                        <b-col md="6">
                          <b-form-group label="Facebook">
                            <b-form-input type="url"
                                          size="sm"
                                          v-model="crud.general.values.facebook"                                    
                                          placeholder="Ingresar url de Facebook">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 

                        <b-col md="6">
                          <b-form-group label="Instagram">
                            <b-form-input type="url"
                                          size="sm"
                                          v-model="crud.general.values.instagram"                                    
                                          placeholder="Ingresar url de Instagram">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 

                        <b-col md="6">
                          <b-form-group label="Twitter">
                            <b-form-input type="url"
                                          size="sm"
                                          v-model="crud.general.values.twitter"                                    
                                          placeholder="Ingresar url de Twitter">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 

                        <b-col md="6">
                          <b-form-group label="Linkedin">
                            <b-form-input type="url"
                                          size="sm"
                                          v-model="crud.general.values.linkedin"                                    
                                          placeholder="Ingresar url de Linkedin">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 

                        <b-col md="6">
                          <b-form-group label="Youtube">
                            <b-form-input type="url"
                                          size="sm"
                                          v-model="crud.general.values.youtube"                                    
                                          placeholder="Ingresar url de Youtube">
                            </b-form-input>
                          </b-form-group>
                        </b-col>                         
                      </b-row>
                    </b-tab>                
                  </b-tabs>
                </b-col>
              </b-row>

              <template v-slot:footer>
                <b-button variant="dark" @click="save('general')" size="sm" class="pull-right">Guardar</b-button>          
              </template>            
            </b-card>              
          </b-tab>                   
          <b-tab title="LOGOS">
            <b-card header-text-variant="white"
                    header-bg-variant="dark"
                    header-border-variant="dark"
                    footer-text-variant="dark"
                    footer-bg-variant="ligth"
                    footer-border-variant="secondary"> 

              <template v-slot:header>
                <strong> LOGOS </strong>  
              </template>
              
              <div v-if="crud.logos.statusSave" class="text-center"> 
                <b-spinner></b-spinner>
              </div>
              <b-row v-else>   
                <b-col md="12">
                  <b-tabs pills card>
                    <b-tab title="Sitio Web" active>                  
                      <b-row>
                        <b-col md="3">      
                          <b-form-group label="Favicon">
                            <Imagen :images="crud.logos.values.favicon" 
                                    @load-image="crud.logos.values.favicon = $event" 
                                    typeImage="Favicon" />
                          </b-form-group>  
                        </b-col>

                        <b-col md="3">      
                          <b-form-group label="Logo Menu">
                            <Imagen :images="crud.logos.values.logo_primary" 
                                    @load-image="crud.logos.values.logo_primary = $event" 
                                    typeImage="Logo_Primary" />
                          </b-form-group>  
                        </b-col>                

                        <b-col md="3">      
                          <b-form-group label="Logo Menu Navbar">
                            <Imagen :images="crud.logos.values.logo_secondary" 
                                    @load-image="crud.logos.values.logo_secondary = $event" 
                                    typeImage="Logo_Secondary" />
                          </b-form-group>  
                        </b-col>

                        <b-col md="3">      
                          <b-form-group label="Logo Footer">
                            <Imagen :images="crud.logos.values.logo_footer" 
                                    @load-image="crud.logos.values.logo_footer = $event" 
                                    typeImage="Logo_Footer" />
                          </b-form-group>  
                        </b-col>
                      </b-row>
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>

              <template v-slot:footer>
                <b-button variant="dark" @click="save('logos')" size="sm" class="pull-right">Guardar</b-button>          
              </template> 
            </b-card>                        
          </b-tab>          
          <b-tab title="METAS">
            <b-card header-text-variant="white"
                    header-bg-variant="dark"
                    header-border-variant="dark"
                    footer-text-variant="dark"
                    footer-bg-variant="ligth"
                    footer-border-variant="secondary"> 

              <template v-slot:header>
                <strong> METAS </strong>  
              </template>

              <div v-if="crud.metas.statusSave" class="text-center"> 
                <b-spinner></b-spinner>
              </div>
              <b-row v-else> 
                <b-col md="12">
                  <b-tabs pills card>
                    <b-tab title="SEO" active>  
                      <b-row>
                        <b-col md="12">      
                          <b-form-group label="Imagen Principal">
                            <Imagen :images="crud.metas.values.image_main" 
                                    @load-image="crud.metas.values.image_main = $event" 
                                    typeImage="ImagenMain" 
                                    :compressImage="true" />
                          </b-form-group>  
                        </b-col>                        
                        <b-col md="12">
                          <b-form-group label="Palabras Claves">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.metas.values.keywords"                                    
                                          placeholder="Ingresar las palabras claves del sitio web">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 
                        <b-col md="12">
                          <b-form-group label="Autor">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.metas.values.author"                                    
                                          placeholder="Ingresar el autor del sitio web">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 
                        <b-col md="12">
                          <b-form-group label="Descripción">
                            <b-form-textarea                                
                              v-model="crud.metas.values.descriptions"
                              size="sm"
                              placeholder="Ingresa la descripción"
                            ></b-form-textarea>                  
                          </b-form-group>
                        </b-col>   
                      </b-row>
                    </b-tab>         
                    <b-tab title="Otros">  
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="Otros Metas" description="Agregue todos los metas que se necesiten <meta />">
                            <b-form-textarea                                
                              v-model="crud.metas.values.others"
                              size="sm"
                              placeholder="Ingresa otros metas"
                            ></b-form-textarea>                  
                          </b-form-group>
                        </b-col>                             
                      </b-row>
                    </b-tab>     
                    <b-tab title="Traductor">  
                      <b-row>
                        <b-col md="12" class="mb-3">
                          <b-form-checkbox                  
                            v-model="crud.metas.values.not_translate_automatic"
                            switch
                          >
                            ¿Bloquear traductor automático del navegador?
                          </b-form-checkbox>
                        </b-col>                            
                      </b-row>
                    </b-tab>                                              
                  </b-tabs>
                </b-col>
              </b-row>

              <template v-slot:footer>
                <b-button variant="dark" @click="save('metas')" size="sm" class="pull-right">Guardar</b-button>          
              </template> 
            </b-card>                        
          </b-tab>           
          <b-tab title="SCRIPTS">
            <b-card header-text-variant="white"
                    header-bg-variant="dark"
                    header-border-variant="dark"
                    footer-text-variant="dark"
                    footer-bg-variant="ligth"
                    footer-border-variant="secondary"> 

              <template v-slot:header>
                <strong> SCRIPTS </strong>  
              </template>
              
              <div v-if="crud.scripts.statusSave" class="text-center"> 
                <b-spinner></b-spinner>
              </div>
              <b-row v-else>   
                <b-col md="12">
                  <b-tabs pills card>
                    <b-tab title="Google Analytics" active>  
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="gtag" description="Ingrese solo la url, sin el tag <script></script>">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.scripts.values.analytics_url"                                    
                                          placeholder="Ingresar la URL del gtag">
                            </b-form-input>            
                          </b-form-group>
                        </b-col>                          
                        <b-col md="12">
                          <b-form-group label="script" description="Ingrese solo el contenido, sin el tag <script></script>">
                            <b-form-textarea                                
                              v-model="crud.scripts.values.analytics"
                              size="sm"
                              placeholder="Ingresar el analytics del sitio web"
                              rows="6"
                            ></b-form-textarea> 
                          </b-form-group>
                        </b-col> 
                      </b-row>
                    </b-tab>
                    <b-tab title="Tag Manager">  
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="script" description="Ingrese solo el contenido, sin el tag <script></script>">
                            <b-form-textarea                                
                              v-model="crud.scripts.values.tagmanager_head"
                              size="sm"
                              placeholder="Ingresar el script para agregar en el head del sitio web"
                              rows="6"
                            ></b-form-textarea> 
                          </b-form-group>
                        </b-col> 

                        <b-col md="12">
                          <b-form-group label="noscript" description="Ingrese solo el contenido, sin el tag <noscript></noscript>">
                            <b-form-textarea                                
                              v-model="crud.scripts.values.tagmanager_body"
                              size="sm"
                              placeholder="Ingresar el código para agregar en el body del sitio web"
                              rows="6"
                            ></b-form-textarea> 
                          </b-form-group>
                        </b-col> 
                      </b-row>
                    </b-tab>
                    <b-tab title="Facebook Pixel">  
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="script" description="Ingrese solo el contenido, sin el tag <script></script>">
                            <b-form-textarea                                
                              v-model="crud.scripts.values.facebookpixel_head"
                              size="sm"
                              placeholder="Ingresar el script para agregar en el head del sitio web"
                              rows="6"
                            ></b-form-textarea> 
                          </b-form-group>
                        </b-col> 

                        <b-col md="12">
                          <b-form-group label="noscript" description="Ingrese solo el contenido, sin el tag <noscript></noscript>">
                            <b-form-textarea                                
                              v-model="crud.scripts.values.facebookpixel_body"
                              size="sm"
                              placeholder="Ingresar el código para agregar en el body del sitio web"
                              rows="6"
                            ></b-form-textarea> 
                          </b-form-group>
                        </b-col> 
                      </b-row>
                    </b-tab>                    
                    <b-tab title="Chat Boot">
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="script" description="Ingrese solo el contenido, sin el tag <script></script>">
                            <b-form-textarea                                
                              v-model="crud.scripts.values.chat"
                              size="sm"
                              placeholder="Ingresar el chat del sitio web"
                              rows="6"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col> 
                      </b-row>
                    </b-tab>
                    <b-tab title="Otros">
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="script" description="Ingrese solo el contenido, sin el tag <script></script>">
                            <b-form-textarea                                
                              v-model="crud.scripts.values.others"
                              size="sm"
                              placeholder="Ingresar otros scripts del sitio web"
                              rows="6"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>                            
                      </b-row>
                    </b-tab>
                  </b-tabs>
                </b-col>                         
              </b-row>

              <template v-slot:footer>
                <b-button variant="dark" @click="save('scripts')" size="sm" class="pull-right">Guardar</b-button>          
              </template> 
            </b-card>                     
          </b-tab>                
          <b-tab title="ESTILOS">            
            <b-card header-text-variant="white"
                    header-bg-variant="dark"
                    header-border-variant="dark"
                    footer-text-variant="dark"
                    footer-bg-variant="ligth"
                    footer-border-variant="secondary"> 

              <template v-slot:header>
                <strong> ESTILOS</strong>  
              </template>
              
              <div v-if="crud.styles.statusSave" class="text-center"> 
                <b-spinner></b-spinner>
              </div>            
              <b-row v-else>
                <b-col md="12">
                  <b-form-group description="Las clases CSS ingresadas se aplicarán a todo el sitio web ">
                    <prism-editor v-model="crud.styles.values.css" language="css" class="content-crud-setting-css"></prism-editor>            
                  </b-form-group>
                </b-col> 
              </b-row>

              <template v-slot:footer>
                <b-button variant="dark" @click="save('styles')" size="sm" class="pull-right">Guardar</b-button>          
              </template> 
            </b-card>                 
          </b-tab>                                                 
        </b-tabs>
      </b-card>
  
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                                            
          </b-col>          
        </b-row>
      </b-card>
    
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image'
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";

  export default {
    components: {
      Imagen,
      PrismEditor,
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.CONTENIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudSettings',
          elements: {}
        }, 
        crud: {
          general: {
            id: 0,
            reference:'',
            statusSave: false,
            values: {
              url_siteweb: '',
              title: '',                 
              address: '',
              phone: '',
              phone_permissions: false,
              whatsapp: '',
              whatsapp_permissions: false,
              email: '',
              email_permissions: false,
              facebook: '',
              instagram: '',
              linkedin: '',
              twitter: '',
              youtube: '',
              activar_titoloconstruye: false,
            }            
          },
          logos: {
            id: 0,
            reference:'',
            statusSave: false,
            values: {
              logo_primary: [],
              logo_secondary: [],
              logo_footer: [],
              favicon: [],              
            }            
          },
          metas: {
            id: 0,
            reference:'',
            statusSave: false,
            values: {
              image_main: [],
              keywords: '',
              descriptions: '',
              author: '',
              others: '',
              not_translate_automatic: true,
            }            
          },    
          scripts: {
            id: 0,
            reference:'',
            statusSave: false,
            values: {
              analytics_url: '',
              analytics: '',
              chat: '',
              others: '',
              tagmanager_head: '',
              tagmanager_body: '',
              facebookpixel_head: '',
              facebookpixel_body: '',              
            }            
          },           
          template: {
            id: 0,
            reference:'',
            statusSave: false,
            values: {
              template: 'website',    
              header: 'siteweb-header-simple',   
              legend_topbar: '', 
              not_showing_access_right: true,
              fonts_primary: '',
              fonts_secondary: '',
              color_primary: '',
              color_secondary: '',
              fonts_color: '',
            }            
          }, 
          styles: {
            id: 0,
            reference:'',
            statusSave: false,
            values: {
              css: '',              
            }            
          },    
        }, 
        arr: {
          select: {
            template: [
              { value: 'website', text: 'Sitio Web' },              
              { value: 'ecommerce', text: 'Comercio Electrónico' },              
              { value: 'marketplace', text: 'Marketplace' },              
              { value: 'courses', text: 'Cursos' },              
            ],            
            header: [
              { value: 'siteweb-header-simple', text: 'Simple-1' },
              { value: 'siteweb-header-simple-two', text: 'Simple-2' },
              { value: 'siteweb-header-background', text: 'Background-2' }
            ],            
          }
        }                           
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    methods: {   
      selectHeader() {                
        if(this.crud.template.values.template == 'website')  {
          if(!this.crud.template.values.header) {
            this.crud.template.values.header = 'siteweb-header-simple'
          }

          this.arr.select.header = []
          this.arr.select.header.push({ value: 'siteweb-header-simple', text: 'Simple-1' })
          this.arr.select.header.push({ value: 'siteweb-header-simple-two', text: 'Simple-2' })
          this.arr.select.header.push({ value: 'siteweb-header-background', text: 'Background-2' })
        }
        if(this.crud.template.values.template == 'ecommerce')  {          
          if(!this.crud.template.values.header) {
            this.crud.template.values.header = 'ecommerce-header-simple'
          }

          this.arr.select.header = []
          this.arr.select.header.push({ value: 'ecommerce-header-simple', text: 'Simple-1' })          
          this.arr.select.header.push({ value: 'ecommerce-header-simple-two', text: 'Simple-2' })
          this.arr.select.header.push({ value: 'ecommerce-header-simple-three', text: 'Simple-3' })
          this.arr.select.header.push({ value: 'ecommerce-header-background', text: 'Background-1' })
          this.arr.select.header.push({ value: 'ecommerce-header-background-topbar', text: 'Background-2' })          
        }
        if(this.crud.template.values.template == 'marketplace')  {          
          if(!this.crud.template.values.header) {
            this.crud.template.values.header = 'marketplace-header-simple'
          }

          this.arr.select.header = []
          this.arr.select.header.push({ value: 'marketplace-header-simple', text: 'Simple-1' })
        }
        if(this.crud.template.values.template == 'courses')  {          
          if(!this.crud.template.values.header) {
            this.crud.template.values.header = 'courses-header-background'
          }

          this.arr.select.header = []          
          this.arr.select.header.push({ value: 'courses-header-background', text: 'Background-1' })          
        }                        
      },  
      load() {        
        let loader = this.$loading.show();    

        var result = serviceAPI.obtenerSettings()

        result.then((response) => { 
          var data = response.data
          var general = {}
          var logos = {}
          var metas = {}
          var scripts = {}
          var template = {}
          var styles = {}

          data.forEach(element => {            
            switch(element.reference) {
              case 'setting_general':    
                this.crud.general.id = element.id
                this.crud.general.reference = element.reference                          
                
                if(element.content) {
                  general = JSON.parse(element.content)
                }                
                break;

              case 'setting_logos':    
                this.crud.logos.id = element.id
                this.crud.logos.reference = element.reference                          
                
                if(element.content) {                                
                  logos = JSON.parse(element.content)
                }                
                break;

              case 'setting_metas':    
                this.crud.metas.id = element.id
                this.crud.metas.reference = element.reference                          
                
                if(element.content) {
                  metas = JSON.parse(element.content)
                }                
                break;

              case 'setting_scripts':    
                this.crud.scripts.id = element.id
                this.crud.scripts.reference = element.reference                          
                
                if(element.content) {
                  scripts = JSON.parse(element.content)
                }                
                break;

              case 'setting_template':    
                this.crud.template.id = element.id
                this.crud.template.reference = element.reference                          
                
                if(element.content) {
                  template = JSON.parse(element.content)
                }                
                break;

              case 'setting_styles':    
                this.crud.styles.id = element.id
                this.crud.styles.reference = element.reference                          
                
                if(element.content) {
                  styles = JSON.parse(element.content)
                }                
                break;                
            }            
          });
          
          if(general) {
            this.crud.general.values.url_siteweb = general.url_siteweb  
            this.crud.general.values.title = general.title               
            this.crud.general.values.address = general.address
            this.crud.general.values.phone = general.phone    
            this.crud.general.values.phone_permissions = general.phone_permissions
            this.crud.general.values.whatsapp = general.whatsapp    
            this.crud.general.values.whatsapp_permissions = general.whatsapp_permissions
            this.crud.general.values.email = general.email            
            this.crud.general.values.email_permissions = general.email_permissions
            this.crud.general.values.facebook = general.facebook    
            this.crud.general.values.instagram = general.instagram  
            this.crud.general.values.linkedin = general.linkedin
            this.crud.general.values.twitter = general.twitter
            this.crud.general.values.youtube = general.youtube  
            this.crud.general.values.activar_titoloconstruye = general.activar_titoloconstruye
          } 

          if(logos) {              
            var logo_primary = []    
            if(Array.isArray(logos.logo_primary)) {
              logo_primary = logos.logo_primary
            } else {
              if(logos.logo_primary!= undefined) {
                if(logos.logo_primary.length > 0) {
                  logo_primary = [{ path: logos.logo_primary }]
                } else {
                  logo_primary = []
                }
              }
            } 

            var logo_secondary = []    
            if(Array.isArray(logos.logo_secondary)) {
              logo_secondary = logos.logo_secondary
            } else {
              if(logos.logo_secondary!= undefined) {
                if(logos.logo_secondary.length > 0) {
                  logo_secondary = [{ path: logos.logo_secondary }]
                } else {
                  logo_secondary = []
                }
              }
            }

            var logo_footer = []    
            if(Array.isArray(logos.logo_footer)) {
              logo_footer = logos.logo_footer
            } else {
              if(logos.logo_footer!= undefined) {
                if(logos.logo_footer.length > 0) {
                  logo_footer = [{ path: logos.logo_footer }]
                } else {
                  logo_footer = []
                }
              }
            }

            var favicon = []    
            if(Array.isArray(logos.favicon)) {
              favicon = logos.favicon
            } else {
              if(logos.favicon!= undefined) {
                if(logos.favicon.length > 0) {
                  favicon = [{ path: logos.favicon }]
                } else {
                  favicon = []
                }
              }
            }             
            this.crud.logos.values.logo_primary = logo_primary
            this.crud.logos.values.logo_secondary = logo_secondary
            this.crud.logos.values.logo_footer = logo_footer
            this.crud.logos.values.favicon = favicon            
          } 

          if(metas) {
            var image_main = []    
            if(Array.isArray(metas.image_main)) {
              image_main = metas.image_main
            } else {
              if(metas.image_main!= undefined) {
                if(metas.image_main.length > 0) {
                  image_main = [{ path: metas.image_main }]
                } else {
                  image_main = []
                }
              }
            }                
            this.crud.metas.values.image_main = image_main
            this.crud.metas.values.keywords = metas.keywords
            this.crud.metas.values.descriptions = metas.descriptions            
            this.crud.metas.values.author = metas.author            
            this.crud.metas.values.others = metas.others
            this.crud.metas.values.not_translate_automatic = metas.not_translate_automatic
          } 

          if(scripts) {
            this.crud.scripts.values.analytics_url = scripts.analytics_url
            this.crud.scripts.values.analytics = scripts.analytics
            this.crud.scripts.values.chat = scripts.chat
            this.crud.scripts.values.others = scripts.others
            this.crud.scripts.values.tagmanager_head = scripts.tagmanager_head
            this.crud.scripts.values.tagmanager_body = scripts.tagmanager_body
            this.crud.scripts.values.facebookpixel_head = scripts.facebookpixel_head
            this.crud.scripts.values.facebookpixel_body = scripts.facebookpixel_body
          } 

          if(template) {            
            if(template.template) {
              this.crud.template.values.template = template.template
            }

            if(template.header) {
              this.crud.template.values.header = template.header
            }
            
            this.crud.template.values.legend_topbar = template.legend_topbar
            this.crud.template.values.not_showing_access_right = template.not_showing_access_right
            
            if(template.fonts_primary) {
              this.crud.template.values.fonts_primary = template.fonts_primary        
            } else {
              this.crud.template.values.fonts_primary = 'https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap'
            }

            if(template.fonts_secondary) {
              this.crud.template.values.fonts_secondary = template.fonts_secondary
            } else {
              this.crud.template.values.fonts_secondary = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap'
            }

            if(template.color_primary) {
              this.crud.template.values.color_primary = template.color_primary   
            } else {
              this.crud.template.values.color_primary = '#FF324D'
            }            
            
            if(template.color_secondary) {
              this.crud.template.values.color_secondary = template.color_secondary
            } else {
              this.crud.template.values.color_secondary = '#f7f8fb'
            }

            if(template.fonts_color) {
              this.crud.template.values.fonts_color = template.fonts_color
            } else {
              this.crud.template.values.fonts_color = '#000'
            }         
          } 
          
          if(styles) {
            this.crud.styles.values.css = styles.css            
          } 

          loader.hide()

        })
        .catch(error => {                   
          loader.hide()                 
          this.$awn.alert(Error.showError(error))
        });        
      },
      save(type) {        
        if(type=='general') {
          let loader = this.$loading.show();
          this.crud.general.statusSave = true

          var result = serviceAPI.editarSettings(this.crud.general.id, this.crud.general);
          
          result.then((response) => {          
            loader.hide()
            this.crud.general.statusSave = false
            this.load()
            this.$awn.success("Datos guardados con éxito");
          })
          .catch(error => {
            this.load()
            loader.hide()
            this.crud.general.statusSave = false
            this.$awn.alert(Error.showError(error));
          })
        }

        if(type=='logos') {

          let loader = this.$loading.show();
          this.crud.logos.statusSave = true

          var logo_primary = new FormData();
          this.crud.logos.values.logo_primary.forEach((value, key) => {
            logo_primary.append(key, value.path);
          })

          var logo_secondary = new FormData();
          this.crud.logos.values.logo_secondary.forEach((value, key) => {
            logo_secondary.append(key, value.path);
          })

          var logo_footer = new FormData();
          this.crud.logos.values.logo_footer.forEach((value, key) => {
            logo_footer.append(key, value.path);
          })

          var favicon = new FormData();
          this.crud.logos.values.favicon.forEach((value, key) => {
            favicon.append(key, value.path);
          })
          
          var result = serviceAPI.editarSettingsImages(this.crud.logos.id, JSON.stringify(this.crud.logos), logo_primary, logo_secondary, logo_footer, favicon);
          
          result.then((response) => {          
            loader.hide()
            this.crud.logos.statusSave = false
            this.load()
            this.$awn.success("Datos guardados con éxito");
          })
          .catch(error => {
            this.load()
            loader.hide()
            this.crud.logos.statusSave = false
            this.$awn.alert(Error.showError(error));
          })

        }

        if(type=='metas') {

          let loader = this.$loading.show();
          this.crud.metas.statusSave = true
          var imageTMP = this.crud.metas.values.image_main

          this.crud.metas.values.image_main = ''
          var result = serviceAPI.editarSettings(this.crud.metas.id, this.crud.metas);

          result.then((response) => {          
            this.crud.metas.values.image_main = imageTMP
            var image_main = new FormData();
            this.crud.metas.values.image_main.forEach((value, key) => {
              image_main.append(key, value.path);
            })                    
                                    
            var result1 = serviceAPI.editarSettingsSEO(this.crud.metas.id, JSON.stringify(this.crud.metas), image_main);
            result1.then((response1) => {          
              loader.hide()
              this.crud.metas.statusSave = false
              this.load()
              this.$awn.success("Datos guardados con éxito");
            })
          })
          .catch(error => {
            this.load()
            loader.hide()
            this.crud.metas.statusSave = false
            this.$awn.alert(Error.showError(error));
          })

        }     

        if(type=='scripts') {

          let loader = this.$loading.show();
          this.crud.scripts.statusSave = true

          var result = serviceAPI.editarSettings(this.crud.scripts.id, this.crud.scripts);
          
          result.then((response) => {          
            loader.hide()
            this.crud.scripts.statusSave = false
            this.load()
            this.$awn.success("Datos guardados con éxito");
          })
          .catch(error => {
            this.load()
            loader.hide()
            this.crud.scripts.statusSave = false
            this.$awn.alert(Error.showError(error));
          })

        }

        if(type=='template') {
         
         if(!this.crud.template.values.template) {
            this.$awn.alert('Seleccione una plantilla')
            return false
          }          
          if(!this.crud.template.values.header) {
            this.$awn.alert('Seleccione una cabecera')
            return false
          }

          let loader = this.$loading.show();
          this.crud.template.statusSave = true

          var result = serviceAPI.editarSettings(this.crud.template.id, this.crud.template);
          
          result.then((response) => {          
            loader.hide()
            this.crud.template.statusSave = false
            this.load()
            this.$awn.success("Datos guardados con éxito");
          })
          .catch(error => {
            this.load()
            loader.hide()
            this.crud.template.statusSave = false
            this.$awn.alert(Error.showError(error));
          })

        }

        if(type=='styles') {

          let loader = this.$loading.show();
          //this.crud.styles.statusSave = true

          var result = serviceAPI.editarSettings(this.crud.styles.id, this.crud.styles);
          
          result.then((response) => {          
            loader.hide()
            //this.crud.styles.statusSave = false
            this.load()
            this.$awn.success("Datos guardados con éxito");
          })
          .catch(error => {
            this.load()
            loader.hide()
            //this.crud.styles.statusSave = false
            this.$awn.alert(Error.showError(error));
          })

        }
      },      
    }    
  }
</script>
<style>
  .content-crud-setting-css {
    height: 500px;
  }
  .content-crud-setting-css pre {
    height: 500px;
  }
</style>